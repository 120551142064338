.status-card {
  padding: 28px;
  display: flex;
  align-items: center;
  /* background-color: var(--main-bg); */

  box-shadow: 0px 0px 10px 3px rgba(218, 218, 218, 0.585);
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: white;
  transition: color 0.5s ease 0s;
  margin-bottom: 30px;
}

.status-card__icon {
  width: 20%;
  height: 100%;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.status-card__info {
  flex-grow: 1;
  /* text-align: center; */
  z-index: 1;
  margin-left: 7px;
  text-transform: capitalize;
}

.status-card__info > h4 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}
.status-card__count {
  font-size: 1.8rem;
}
.status-card::before {
  content: "";
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-image: linear-gradient(
    to top right,
    var(--main-color),
    var(--second-color)
  );
  position: absolute;
  left: -50%;
  top: 0;
  transform: scale(0);
  transition: transform 0.8s ease 0s;
}

.status-card:hover::before {
  transform: scale(3);
}

.status-card:hover {
  color: var(--txt-white);
}
