.btn {
  padding: 0.3em;
  /* width: 100px; */
  border-radius: 5px;
  background-color: #349eff;
  color: white;
  font-weight: bold;
  padding-inline: 30px;
}

.btn:hover {
  background-color: #9fccf3;
}
