.chat-side-menu {
  flex: 1.9;
  max-height: min-content;
  max-height: 92vh;

  border-right: 1px solid rgb(210, 210, 210);
  overflow-y: scroll;
}
.chat-user-list {
  max-height: 87vh;
}
.chat-user-card {
  cursor: pointer;
}
.chat-user-card h6 {
  font-weight: 400;
}
.chat-top-bar {
  background-color: #efeded;
  display: flex;
  align-items: center;
  padding: 7px;
}
.avatar {
  border-radius: 100px;
}
.avatar img {
  object-fit: contain;
  border-radius: 100px;
  width: 3rem;
  margin: 0.3rem;
}

.chat-main-box {
  position: relative;
  display: flex;
  bottom: 0;
  min-height: 78vh;

  max-height: 78vh;
  flex-direction: column;
  background-color: rgb(169, 169, 169);
}
#text-chat {
  width: 70%;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(50, 50, 50);
}
.chat-section {
  flex: 15;
  background-color: white;
  overflow-y: scroll;
}
.footer-conversation {
  flex: 1;
  background-color: #fafafa;
}
.send-msg-btn {
  margin-top: 4px;
  padding: 0px;
}
.chat-card {
  display: block;
}
.chat-user-avatar {
  display: flex;
  position: relative;
  align-self: center;
}
@media (max-width: 768px) {
  #text-chat {
    width: 90%;
  }
  .chat-bottom-sheet {
    min-height: 78vh;
  }
  .chat-side-menu {
    display: none;
  }
  .send-msg-btn {
    margin-top: -10px;
    padding: 0px;
  }
}
