@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

:root {
  --main-bg: #fafafa;
  --second-bg: #f1f1f1;
  --txt-color: #2c2c2c;
  --txt-white: rgb(42, 42, 42);
  --main-color: #349eff;
  --second-color: #62ff94;
  --box-shadow: rgba(80, 80, 80, 0.2) 0px 5px 10px;

  --main-bg-light: #2d2d2d;
  --second-bg-light: #202020;
  --txt-color-light: #353535;
  --box-shadow-light: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #313131;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #151515;
  --second-color-green: #272727;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  --sidebar-width: 270px;
  --border-radius: 15px;
  --topnav-height: 2rem;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
  background-color: var(--main-bg);
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: var(--main-color);
}
title {
  font-size: 28px;
  color: #332f32;
}
button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;
  background-color: white;
}

‸ut:focus {
  border: 2px solid var(--main-color);
}

.page-header {
  margin-bottom: 40px;
  color: #252525;
  text-transform: capitalize;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  /* background-color: var(--main-bg); */
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
h2 {
  color: #000 !important;
}
.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: rgb(33, 33, 33);
}

.blue-color {
  background-color: var(--main-color-blue);
  color: rgb(30, 30, 30);
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}

.textfield {
  background-color: rgb(255, 255, 255);
  padding: 10px 10px;
  margin-bottom: 2rem;
  border-radius: 8px;
  border: 1px solid rgba(226, 226, 226, 0.484);
  width: 100%;
  font-weight: 500;

  color: #2e2e2e;
}
h4 {
  font-size: 20px;
  font-weight: 400;
  color: #585858;
}
label {
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  color: #4d4d4d;
  font-size: 13px;
  margin: 0.3rem;
}
.flex {
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-direction: row;
  display: flex;
  padding: 0%;
  width: 100%;
}
table {
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;
}
