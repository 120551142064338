.sidebar__logo > img {
  height: 40px;
}

.sidebar__item {
  padding: 0 20px;
}

.sidebar__item-inner {
  padding: 15px 25px;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: color 0.3s ease 0s;
}

.sidebar__item-inner > i {
  margin-right: 10px;
  font-size: 1.2rem;
}

.sidebar__item-inner > span {
  text-transform: capitalize;
}

.sidebar__item-inner:hover {
  color: var(--main-color);
}

.sidebar__item-inner.active {
  border-radius: var(--border-radius);
  background-image: linear-gradient(
    to right,
    var(--main-color),
    var(--second-color)
  );
  color: var(--txt-white);
}

.sidebar {
  height: 100vh;
  color: rgb(33, 33, 33);
  display: block;

  overflow-y: scroll;
  border-right: 1px solid #c1c1c1;
}
.app-navbar {
  display: block;
  overflow-y: scroll;
  .item-title {
    display: flex;
    font-size: 13px;
    color: #626262;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .active-menu-item {
    color: red;
  }
  .menu-title {
    display: flex;
    justify-content: space-between;
    color: #626262;
    cursor: pointer;
    transition: all 2.3s;

    .item-title h5 {
      font-size: small;
    }
  }

  .menu-childrens {
    font-size: 12px;
    margin-left: 2.7rem;
    margin-block: 1rem;
    color: #626262;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    border: none;
  }
  .menu-childrens a {
    border: none;
    text-decoration: none;
  }
  .active-menu-item {
    color: rgb(235, 235, 235);
    background-color: #01684b;
    padding: 10px;
    border-radius: 70px;
  }
}
@media (max-width: 700px) {
  .sidebar {
    height: 57px;
    background-color: #ffffff;
    max-height: 60px;
    padding: 0.4rem;
    // background-color: white;
    // box-shadow: 2px 2px 10px  4px rgb(225, 225, 225);
  }

  .mobile-nav {
    top: 0;
    right: 0;
    padding: 3rem;
    position: sticky;
  }
}
